import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import listContent from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import Post from './Post';
import Group from './Group';
import Event from './Event';
import Vehicle from './Vehicle';

const ShowReport = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [report, setReport] = useState(null);
  const [photos, setPhotos] = useState([]);
  const buttonStyle = {
    marginRight: 10
  };

  const markReportAsResolved = (decision = 'REPORT_IGNORED') => {
    setIsLoading(true);
    dispatchAPI('PATCH', {
      url: `/reports/${id}`,
      body: {
        status: 'RESOLVED',
        decision
      }
    })
      .then(() => {
        setReport((prev) => ({
          ...prev,
          status: 'RESOLVED'
        }));
      })
      .catch((e) => {
        message(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const removePost = () => {
    setIsLoading(true);
    dispatchAPI('PATCH', {
      url: `/posts/${report.target.post._id}`,
      body: {
        removedByModerator: true
      }
    })
      .then(() => {
        markReportAsResolved('POST_REMOVED');
      })
      .catch((e) => {
        message(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const removeGroup = () => {
    setIsLoading(true);
    dispatchAPI('PATCH', {
      url: `/groups/${report.target.group._id}`,
      body: {
        removedByModerator: true
      }
    })
      .then(() => {
        markReportAsResolved('GROUP_REMOVED');
      })
      .catch((e) => {
        message(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const removeEvent = () => {
    setIsLoading(true);
    dispatchAPI('PATCH', {
      url: `/events/${report.target.event._id}`,
      body: {
        removedByModerator: true
      }
    })
      .then(() => {
        markReportAsResolved('EVENT_REMOVED');
      })
      .catch((e) => {
        message(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const removeVehicle = () => {
    setIsLoading(true);
    dispatchAPI('PATCH', {
      url: `/vehicles/${report.target.vehicle._id}`,
      body: {
        removedByModerator: true
      }
    })
      .then(() => {
        markReportAsResolved('VEHICLE_REMOVED');
      })
      .catch((e) => {
        message(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const removeComment = () => {
    setIsLoading(true);
    dispatchAPI('PATCH', {
      url: `/comments/${report.target.comment._id}`,
      body: {
        removedByModerator: true
      }
    })
      .then(() => {
        markReportAsResolved('COMMENT_REMOVED');
      })
      .catch((e) => {
        message(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const blockUser = () => {
    setIsLoading(true);

    const userId =
      report.type === 'USER'
        ? report.target.user._id
        : report.target.post.owner._id;
    dispatchAPI('PATCH', {
      url: `/users/${userId}`,
      body: {
        accountDisabledByAdmin: true
      }
    })
      .then(() => {
        markReportAsResolved('USER_BLOCKED');
      })
      .catch((e) => {
        message(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getPhotos = async (post) => {
    const loadPhotos = [];
    if (post?.photos) {
      for (let i = 0; i < post.photos.length; i += 1) {
        loadPhotos.push(
          dispatchAPI('GET', {
            url: `/files/${post.photos[i]}`,
            responseType: 'blob'
          })
        );
      }
    }
    // await all
    return (await Promise.all(loadPhotos)).map((result) =>
      URL.createObjectURL(result.data)
    );
  };

  const getPhoto = async (photoId) => {
    const result = await dispatchAPI('GET', {
      url: `/files/${photoId}`,
      responseType: 'blob'
    });
    return URL.createObjectURL(result.data);
  };

  const getReport = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/reports/${id}?populate=source,target.user`
      });

      if (data.type === 'POST') {
        const post = await dispatchAPI('GET', {
          url: `/posts/${data.target.post}?populate=owner.user,tags`
        });
        getPhotos(post.data).then((urls) => {
          setPhotos(urls);
        });
        data.target.post = post.data;
      }

      if (data.type === 'COMMENT') {
        const comment = await dispatchAPI('GET', {
          url: `/comments/${data.target.comment}?populate=owner`
        });
        data.target.comment = comment.data;
      }

      if (data.type === 'EVENT') {
        const event = await dispatchAPI('GET', {
          url: `/events/${data.target.event}?populate=owner`
        });

        if (event.data.photo) {
          getPhoto(event.data.photo).then((url) => {
            setPhotos([url]);
          });
        }

        data.target.event = event.data;
      }

      if (data.type === 'GROUP') {
        const group = await dispatchAPI('GET', {
          url: `/groups/${data.target.group}?populate=owner`
        });
        if (group.data.photo) {
          const url = await getPhoto(group.data.photo);
          setPhotos([url]);
        }

        data.target.group = group.data;
      }

      if (data.type === 'VEHICLE') {
        const vehicle = await dispatchAPI('GET', {
          url: `/vehicles/${data.target.vehicle}?populate=owner`
        });

        if (vehicle.data.photos) {
          const urls = await getPhotos(vehicle.data);
          setPhotos(urls);
        }
        data.target.vehicle = vehicle.data;
      }

      setReport(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getReport();
    })();
  }, [getReport]);

  const renderBlockContentButton = () => {
    // content can be post, event, group, vehicle, or comment. If it's type user, then return nothing
    switch (report?.type) {
      case 'POST':
        return (
          <Popconfirm
            title={t('reports.messages.confirm')}
            onConfirm={removePost}
          >
            <Button
              style={{ ...buttonStyle, ...{ backgroundColor: 'orange' } }}
              type="primary"
            >
              {t('reports.show.block_post')}
            </Button>
          </Popconfirm>
        );
      case 'GROUP':
        return (
          <Popconfirm
            title={t('reports.messages.confirm')}
            onConfirm={removeGroup}
          >
            <Button
              style={{ ...buttonStyle, ...{ backgroundColor: 'orange' } }}
              type="primary"
            >
              {t('reports.show.block_group')}
            </Button>
          </Popconfirm>
        );
      case 'EVENT':
        return (
          <Popconfirm
            title={t('reports.messages.confirm')}
            onConfirm={removeEvent}
          >
            <Button
              style={{ ...buttonStyle, ...{ backgroundColor: 'orange' } }}
              type="primary"
            >
              {t('reports.show.block_event')}
            </Button>
          </Popconfirm>
        );
      case 'COMMENT':
        return (
          <Popconfirm
            title={t('reports.messages.confirm')}
            onConfirm={removeComment}
          >
            <Button
              style={{ ...buttonStyle, ...{ backgroundColor: 'orange' } }}
              type="primary"
            >
              {t('reports.show.block_comment')}
            </Button>
          </Popconfirm>
        );
      case 'VEHICLE':
        return (
          <Popconfirm
            title={t('reports.messages.confirm')}
            onConfirm={removeVehicle}
          >
            <Button
              style={{ ...buttonStyle, ...{ backgroundColor: 'orange' } }}
              type="primary"
            >
              {t('reports.show.block_vehicle')}
            </Button>
          </Popconfirm>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <PageHeaderCustom title={t('reports.show.title')} />
      <ContentCustom>
        <Row gutter={[0, 24]}>
          <Skeleton
            loading={isLoading}
            title={0}
            paragraph={0}
            avatar={{ size: 64 }}
            active
          />
        </Row>
        <Row>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <DescriptionList data={listContent(report || {}, t)} translate />
          </Skeleton>
        </Row>
        {report?.target?.post && (
          <Row>
            <Skeleton
              loading={isLoading}
              title={0}
              paragraph={{ rows: 5 }}
              avatar={{ size: 64 }}
              active
            >
              <div
                style={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  marginBottom: 10,
                  marginTop: 10
                }}
              >
                Publication Signalée :
              </div>
              <Post post={{ ...report.target.post, photos }} />
            </Skeleton>
          </Row>
        )}
        {report?.target?.event && (
          <Row>
            <Skeleton
              loading={isLoading}
              title={0}
              paragraph={{ rows: 5 }}
              avatar={{ size: 64 }}
              active
            >
              <div
                style={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  marginBottom: 10,
                  marginTop: 10
                }}
              >
                Évènement Signalé :
              </div>
              <Event event={{ ...report.target.event, photo: photos[0] }} />
            </Skeleton>
          </Row>
        )}
        {report?.target?.group && (
          <Row>
            <Skeleton
              loading={isLoading}
              title={0}
              paragraph={{ rows: 5 }}
              avatar={{ size: 64 }}
              active
            >
              <div
                style={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  marginBottom: 10,
                  marginTop: 10
                }}
              >
                Groupe Signalé :
              </div>
              <Group group={{ ...report.target.group, photo: photos[0] }} />
            </Skeleton>
          </Row>
        )}
        {report?.target?.vehicle && (
          <Row>
            <Skeleton
              loading={isLoading}
              title={0}
              paragraph={{ rows: 5 }}
              avatar={{ size: 64 }}
              active
            >
              <div
                style={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  marginBottom: 10,
                  marginTop: 10
                }}
              >
                Véhicule Signalé :
              </div>
              <Vehicle vehicle={{ ...report.target.group, photos }} />
            </Skeleton>
          </Row>
        )}
        {report?.target?.comment && (
          <Row>
            <Skeleton
              loading={isLoading}
              title={0}
              avatar={{ size: 64 }}
              active
            >
              <div
                style={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  marginBottom: 10,
                  marginTop: 10,
                  justifyContent: 'center'
                }}
              >
                Commentaire Signalé:
              </div>
              {report.target.comment.content}
            </Skeleton>
          </Row>
        )}
        {report?.status !== 'RESOLVED' && (
          <Row>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Popconfirm
                title={t('reports.messages.confirm')}
                onConfirm={blockUser}
              >
                <Button
                  style={{ ...buttonStyle, ...{ backgroundColor: 'red' } }}
                  type="primary"
                >
                  {t('reports.show.block_user')}
                </Button>
              </Popconfirm>
              {renderBlockContentButton()}
              <Popconfirm
                title={t('reports.messages.confirm')}
                onConfirm={markReportAsResolved}
              >
                <Button
                  style={{ ...buttonStyle, ...{ backgroundColor: 'green' } }}
                  type="primary"
                >
                  {t('reports.show.ignore')}
                </Button>
              </Popconfirm>
            </Skeleton>
          </Row>
        )}
      </ContentCustom>
    </>
  );
};

export default ShowReport;
