import React from 'react';
import {
  HomeOutlined,
  UserOutlined,
  SettingOutlined,
  WarningOutlined
} from '@ant-design/icons';

const navMenuLogos = {
  HOME: <HomeOutlined />,
  USERS: <UserOutlined />,
  CONFIGURATION: <SettingOutlined />,
  REPORTS: <WarningOutlined />
};

export default navMenuLogos;
