import { Skeleton } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';

// Over ten different commonly used charts are available

import { ContentCustom, PageHeaderCustom } from '../../components';

export const Home = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);
  const [reportCount, setReportCount] = useState(0);
  const [tbc, setTbc] = useState({
    brands: 0,
    tags: 0,
    activities: 0
  });

  const getActiveReports = () => {
    setIsLoading(true);
    dispatchAPI('GET', {
      url: '/reports?status=PENDING'
    })
      .then((res) => {
        setReportCount(res.data.filter((r) => r.status === 'PENDING').length);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getTBC = () => {
    setIsLoading(true);
    const brands = dispatchAPI('GET', {
      url: '/brands?status=TBC'
    });
    const tags = dispatchAPI('GET', {
      url: '/tags?status=TBC'
    });
    const activities = dispatchAPI('GET', {
      url: '/activities?status=TBC'
    });
    Promise.all([brands, tags, activities])
      .then((res) => {
        setTbc({
          brands: res[0].data.length,
          tags: res[1].data.length,
          activities: res[2].data.length
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getActiveReports();
    getTBC();
  }, []);

  // TODO :
  // 1. Get the number of pending reports
  // 2. Get the number of installations
  // 3. Get the number of users active in the last 30 days
  // 4. Get the number of screen change per session
  // 5. Get the average time spent on the app per session
  // 6. Type per screen types (post, events, groups, garage)
  // 7. iOS / Android ratio

  return (
    <>
      <PageHeaderCustom title={t('home.title')} />
      <ContentCustom>
        <Skeleton
          active
          loading={isLoading}
          paragraph={{ rows: 4 }}
          avatar={{ size: 64 }}
        >
          <Link to="/reports">
            <div style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 10 }}>
              {t('home.awaiting_reports')} : {reportCount}
            </div>
          </Link>

          <Link to="/configuration/brands">
            <div style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 10 }}>
              {t('home.awaiting_brands')} : {tbc.brands}
            </div>
          </Link>

          <Link to="/configuration/tags">
            <div style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 10 }}>
              {t('home.awaiting_tags')} : {tbc.tags}
            </div>
          </Link>

          <Link to="/configuration/activities">
            <div style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 10 }}>
              {t('home.awaiting_activities')} : {tbc.activities}
            </div>
          </Link>
        </Skeleton>
      </ContentCustom>
    </>
  );
};
