import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ActivityRouter } from './activities/ActivityRouter';
import { BrandRouter } from './brands/BrandRouter';
import { TagRouter } from './tags/TagRouter';
import { subRoutes } from '../../utils/constants/adminRoutes';

export const ConfigurationRouter = () => (
  <Routes>
    <Route
      path={`/${subRoutes.CONFIGURATION.ACTIVITIES}/*`}
      element={<ActivityRouter />}
    />
    <Route
      path={`/${subRoutes.CONFIGURATION.BRANDS}/*`}
      element={<BrandRouter />}
    />
    <Route
      path={`/${subRoutes.CONFIGURATION.TAGS}/*`}
      element={<TagRouter />}
    />
  </Routes>
);
