export const userRoles = {
  GUEST: 'green',
  USER: 'blue',
  'SUPER-USER': 'geekblue',
  ADMIN: 'red',
  'SUPER-ADMIN': 'magenta'
};

export const statuses = {
  ACTIVE: 'green',
  TBC: 'grey',
  ARCHIVED: 'red'
};

export const pendingTasksCat = {
  POST: 'green',
  PATCH: 'blue',
  DELETE: 'red'
};

export const reportStatuses = {
  PENDING: 'orange',
  RESOLVED: 'green'
};

export const reportTypes = {
  USER: 'blue',
  POST: 'yellow',
  COMMENT: 'violet',
  GROUP: 'cyan',
  VEHICLE: 'green',
  EVENT: 'red'
};
