import React, { useCallback, useEffect, useState } from 'react';
import { Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { statuses } from '../../../utils/constants/tagColors';

const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});

  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['status'],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums?.status || []).map((status) => (
            <Option key={status} value={status}>
              <Tag color={statuses[status]}>{t(`brands.tags.${status}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/brands/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
