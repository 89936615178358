// post component : on top, the images horizontally, then the description, then the tags, then the mentions
import PropTypes from 'prop-types';
import React from 'react';
import { Tag, Image, Row, Col } from 'antd';

const Post = ({ post }) => {
  const { photos, description: postDescription, tags } = post;

  return (
    <Col style={{ margin: 20 }}>
      {photos && photos.length > 0 && (
        <Row className="post-images">
          {photos.map((image) => (
            <Image
              style={{ paddingRight: 20 }}
              src={image}
              alt="post"
              key={image}
              height={200}
            />
          ))}
        </Row>
      )}
      <Row style={{ fontSize: 20, fontWeight: 'bold', marginTop: 20 }}>
        Description{' '}
      </Row>
      {postDescription && <Row>{postDescription}</Row>}
      {tags && tags.length > 0 && (
        <Row className="post-tags">
          {tags.map((tag) => (
            <Tag color="blue">{tag.label}</Tag>
          ))}
        </Row>
      )}
      {/* {mentions && mentions.length > 0 && (
        <Row className="post-mentions">
          {mentions.map((mention) => (
            /Tag color="blue">{mention}</Tag>
          ))}
        </Row>
      )} */}
    </Col>
  );
};

Post.propTypes = {
  post: PropTypes.shape({
    description: PropTypes.string,
    mentions: PropTypes.arrayOf(PropTypes.shape({})),
    photos: PropTypes.shape({
      length: PropTypes.number,
      map: PropTypes.func
    }),
    tags: PropTypes.shape({
      length: PropTypes.number,
      map: PropTypes.func
    })
  }).isRequired
};

export default Post;
