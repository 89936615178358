import React from 'react';
import { Tag } from 'antd';
import { statuses } from '../../../utils/constants/tagColors';

const activityList = (data, t) => {
  const { name, status } = data;

  return [
    {
      label: 'activities.form.name',
      span: 1,
      content: name || '-'
    },
    {
      label: 'activities.form.status',
      span: 3,
      content:
        (status && (
          <Tag color={statuses[status]}>{t(`activities.tags.${status}`)}</Tag>
        )) ||
        '-'
    }
  ];
};

export default activityList;
