import React from 'react';
import { Row, Tag } from 'antd';
import moment from 'moment';
import { reportStatuses, reportTypes } from '../../utils/constants/tagColors';

const reportList = (data, t) => {
  const { type, target, source, reason, description, status, decision } = data;

  return [
    {
      label: 'reports.form.target',
      span: 2,
      content:
        (target &&
          type === 'USER' &&
          `${target.user.first_name} ${target.user.last_name}`) ||
        (type === 'POST' &&
          `${target.post.owner.user.first_name} ${target.post.owner.user.last_name}`) ||
        (type === 'COMMENT' &&
          `${target.comment.owner.first_name} ${target.comment.owner.last_name}`) ||
        (type === 'GROUP' &&
          `${target.group.owner.first_name} ${target.group.owner.last_name}`) ||
        (type === 'VEHICLE' &&
          `${target.vehicle.owner.first_name} ${target.vehicle.owner.last_name}`) ||
        (type === 'EVENT' &&
          `${target.event.owner.first_name} ${target.event.owner.last_name}`) ||
        '-'
    },
    {
      label: 'reports.form.reporter',
      span: 2,
      content: (source && `${source.first_name} ${source.last_name}`) || '-'
    },
    {
      label: 'reports.form.type',
      span: 1,
      content:
        (type && (
          <Tag color={reportTypes[type]}>{t(`reports.types.${type}`)}</Tag>
        )) ||
        '-'
    },
    {
      label: 'reports.form.reason',
      span: 2,
      content: (reason && <Tag>{t(`reports.reasons.${reason}`)}</Tag>) || '-'
    },
    {
      label: 'reports.form.description',
      span: 1,
      content: description || '-'
    },
    {
      label: 'reports.form.status',
      span: 1,
      content:
        (status && (
          <Row>
            <Tag color={reportStatuses[status]}>
              {t(`reports.status.${status}`)}
            </Tag>
            {decision && ` Décision: ${t(`reports.decisions.${decision}`)}`}
          </Row>
        )) ||
        '-'
    },
    {
      label: 'reports.form.created_at',
      span: 4,
      content: moment(data.created_at).format('DD/MM/YYYY HH:mm') || '-'
    }
  ];
};

export default reportList;
