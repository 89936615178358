import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { ListResource } from '../../../components';
import { statuses } from '../../../utils/constants/tagColors';

const ListBrands = () => {
  const { t } = useTranslation();

  const headers = [
    {
      label: 'name',
      key: 'name'
    },
    {
      label: 'status',
      key: 'status'
    }
  ];

  const columns = [
    {
      title: t('brands.form.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('brands.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={statuses[status]}>{t(`brands.tags.${status}`)}</Tag>
      ),
      sorter: true,
      filters: Object.keys(statuses).map((r) => ({
        text: t(`brands.tags.${r}`),
        value:
          r.split('-').length > 1
            ? `${r.split('-')[1].toLowerCase()}s:${r}`
            : `${r.toLowerCase()}s:${r}`
      }))
    }
  ];

  return (
    <ListResource
      resourceName="brands"
      columns={columns}
      headers={headers}
      resourceModelName="Brand"
    />
  );
};

export default ListBrands;
