import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListActivities from './ListActivities';
import CreateUpdateActivity from './CreateUpdateActivity';
import ShowActivity from './ShowActivity';

export const ActivityRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdateActivity purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdateActivity purpose="edit" />} />
    <Route path="/show/:id" element={<ShowActivity />} />
    <Route index element={<ListActivities />} />
  </Routes>
);
