import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListBrands from './ListBrands';
import CreateUpdateBrand from './CreateUpdateBrand';
import ShowBrand from './ShowBrand';

export const BrandRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdateBrand purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdateBrand purpose="edit" />} />
    <Route path="/show/:id" element={<ShowBrand />} />
    <Route index element={<ListBrands />} />
  </Routes>
);
