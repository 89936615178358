import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useFields from './fields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useAuthContext } from '../../../contexts/AuthContext';

const CreateUpdateActivity = ({ purpose }) => {
  const { fields, isFieldsLoading, base64 } = useFields();
  const { activity, setActivity } = useAuthContext();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        date_of_birth: data.date_of_birth && moment(data.date_of_birth)
      })
    },
    onUpdateResource: {
      setBody: (data) => {
        if (data._id === activity._id) setActivity({ ...activity, ...data });
        return {
          ...data,
          ...(base64 ? { photo: base64 } : {})
        };
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="activities"
      resource="activities"
      config={config}
    />
  );
};

CreateUpdateActivity.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateActivity;
