import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Layout, Popover, Radio, Space } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { SettingOutlined } from '@ant-design/icons';
import Logo from '../../assets/images/logo-lg-black.png';
import LogoCollapsed from '../../assets/images/logocollapsed.png';
import HeaderLayout from './HeaderLayout/HeaderLayout';
import { useHandleResize } from '../../utils/HandleResize';
import { UserNavMenu } from './UserNavMenu';
import { UpdateAlert } from '..';
import { useThemeContext } from '../../contexts/ThemeContext';

const { Content, Footer, Sider } = Layout;

const StyledLayout = styled.div`
  height: 100vh;
`;

const StyledSider = styled.div`
  min-height: 100vh;
  z-index: 10;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  overflow-x: hidden;
  position: fixed;
  left: 0;
`;

const LogoDiv = styled.div`
  position: relative;
  height: 64px;
  padding-left: 0;
  overflow: hidden;
  line-height: 64px;
  transition: all 0.3s;
  z-index: 900;
  display: flex;
  justify-content: center;
  img {
    display: inline-block;
    height: 52px;
    vertical-align: middle;
  }
`;

const StyledContent = styled.div`
  margin: 80px 4px 8px 280px;
  padding-right: 4px;
  overflow: auto;
  @media (max-width: 992px) {
    margin-left: 100px;
  }

  @media (max-width: 576px) {
    margin-left: 24px;
  }
`;

const StyledFooter = styled.footer`
  padding: 8px 25px;
  text-align: center;
  margin-left: 255px;
  background-color: var(--bodyBackground);
  color: var(--textColor);
  box-shadow: 0 -1px 4px var(--borderColor);

  @media (max-width: 992px) {
    margin-left: 80px;
  }

  @media (max-width: 576px) {
    margin-left: 0;
  }
`;

export const UserLayout = () => {
  const { t } = useTranslation();
  const { theme, setTheme } = useThemeContext();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 992);
  const { width, height } = useHandleResize();
  const [collapseWidth, setCollapseWidth] = useState(
    window.innerWidth < 576 ? 0 : 80
  );

  const onCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed);
  };

  useEffect(() => {
    if (width < 576) {
      setCollapseWidth(0);
    } else {
      setCollapseWidth(80);
    }
  }, [width, height]);

  useEffect(() => {
    document
      .getElementById('mainContent')
      .scrollTo({ behavior: 'smooth', top: 0 });
  }, [location]);

  const themeSwitches = () => (
    <div style={{ minWidth: 200 }}>
      <Radio.Group onChange={(e) => setTheme(e.target.value)} value={theme}>
        <Space direction="vertical">
          <Radio value="user">Default</Radio>
          <Radio value="assurmax">Assurmax style</Radio>
          <Radio value="dova">Dova style</Radio>
          <Radio value="adm">AdM style</Radio>
          <Radio value="boosterActiv">Booster Activ style</Radio>
          <Radio value="methodeCitoyenne">Methode Citoyenne style</Radio>
        </Space>
      </Radio.Group>
    </div>
  );

  return (
    <StyledLayout as={Layout}>
      <UpdateAlert />
      <StyledSider
        as={Sider}
        width={256}
        breakpoint="lg"
        trigger={null}
        collapsible
        collapsedWidth={collapseWidth}
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <LogoDiv>
          <Link to="/">
            <img alt="Logo" src={collapsed ? LogoCollapsed : Logo} />
          </Link>
        </LogoDiv>
        <UserNavMenu setCollapsed={setCollapsed} />
      </StyledSider>
      <Layout>
        <HeaderLayout
          collapsed={collapsed}
          collapseSider={setCollapsed}
          collapseWidth={collapseWidth}
        />
        <StyledContent as={Content} id="mainContent">
          <Outlet />
        </StyledContent>
        <StyledFooter as={Footer}>
          {`${t('global.title')} ©${moment().year()} powered by `}
          <a href="https://strateg.in">Strateg.in</a>
          <Popover trigger="click" content={themeSwitches} placement="topRight">
            <Button
              style={{ position: 'absolute', right: 24 }}
              type="primary"
              icon={<SettingOutlined />}
            />
          </Popover>
        </StyledFooter>
      </Layout>
    </StyledLayout>
  );
};
