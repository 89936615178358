import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import moment from 'moment';
import { ListResource } from '../../components';
import { reportStatuses, reportTypes } from '../../utils/constants/tagColors';

const ListReports = () => {
  const { t } = useTranslation();

  const headers = [
    {
      label: 'type',
      key: 'type'
    }
  ];

  const columns = [
    {
      title: t('reports.form.type'),
      key: 'type',
      dataIndex: 'type',
      span: 1,
      sorter: true,
      render: (type) => (
        <Tag color={reportTypes[type]}>{t(`reports.types.${type}`)}</Tag>
      )
    },
    {
      title: t('reports.form.status'),
      key: 'status',
      dataIndex: 'status',
      span: 1,
      sorter: true,

      render: (status) => (
        <Tag color={reportStatuses[status]}>
          {t(`reports.status.${status}`)}
        </Tag>
      )
    },
    {
      title: t('reports.form.date'),
      key: 'created_at',
      dataIndex: 'created_at',
      span: 1,
      sorter: true,
      render: (date) => moment(date).format('DD/MM/YYYY HH:mm')
    }
  ];

  return (
    <ListResource
      resourceName="reports"
      columns={columns}
      headers={headers}
      resourceModelName="Report"
    />
  );
};

export default ListReports;
