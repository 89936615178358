// Main nav menuItems
export const routes = {
  HOME: '/',
  USERS: '/users',
  CONFIGURATION: '/configuration',
  REPORTS: '/reports'
};

// Main nav subMenuItems
export const subRoutes = {
  CONFIGURATION: {
    ACTIVITIES: '/activities',
    BRANDS: '/brands',
    TAGS: '/tags'
  }
};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings'
};

// url search parameters
export const pathSearches = {};
