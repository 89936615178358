// post component : on top, the images horizontally, then the description, then the tags, then the mentions
import PropTypes from 'prop-types';
import React from 'react';
import { Tag, Image, Row, Col } from 'antd';

const Event = ({ event }) => {
  const { title, photo, description: eventDescription, tags } = event;

  return (
    <Col style={{ margin: 20 }}>
      {photo && (
        <Row className="post-images">
          <Image
            style={{ paddingRight: 20 }}
            src={photo}
            alt="post"
            key={photo}
            height={200}
          />
        </Row>
      )}
      <Row style={{ fontSize: 20, fontWeight: 'bold', marginTop: 20 }}>
        Titre{' '}
      </Row>
      {title && <Row>{title}</Row>}
      <Row style={{ fontSize: 20, fontWeight: 'bold', marginTop: 20 }}>
        Description{' '}
      </Row>
      {eventDescription && <Row>{event}</Row>}
      {tags && tags.length > 0 && (
        <Row className="post-tags">
          {tags.map((tag) => (
            <Tag color="blue">{tag.label}</Tag>
          ))}
        </Row>
      )}
      {/* {mentions && mentions.length > 0 && (
        <Row className="post-mentions">
          {mentions.map((mention) => (
            /Tag color="blue">{mention}</Tag>
          ))}
        </Row>
      )} */}
    </Col>
  );
};

Event.propTypes = {
  event: PropTypes.shape({
    description: PropTypes.string,
    photo: PropTypes.string,
    tags: PropTypes.shape({
      length: PropTypes.number,
      map: PropTypes.func
    }),
    title: PropTypes.string
  }).isRequired
};

export default Event;
