import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListReports from './ListReports';
import ShowReport from './ShowReport';

export const ReportRouter = () => (
  <Routes>
    <Route path="/show/:id" element={<ShowReport />} />
    <Route index element={<ListReports />} />
  </Routes>
);
