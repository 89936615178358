import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListTags from './ListTags';
import CreateUpdateTag from './CreateUpdateTag';
import ShowTag from './ShowTag';

export const TagRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdateTag purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdateTag purpose="edit" />} />
    <Route path="/show/:id" element={<ShowTag />} />
    <Route index element={<ListTags />} />
  </Routes>
);
